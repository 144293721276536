<!--
File: TreatmentsCosts.vue
Description: component of the Preparatory.vue almost same as WorkList component in TitleList.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-20 md-size-15">
          <BaseDropdown :label="$t('budget.select_year')" v-model="selectedWorklist" :items="savedWorksList"
            :displayField="'year'" :valueField="'work_list_id'" @input="loadTreatmentsList" />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <RegionsDropdown :label="$t('label.select_region')" v-model="selectedRegion" :initial_value="selectedRegion"
            @input="loadTreatmentsList" />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <BaseDropdown :label="$t('label.select_road')" v-model="selectedRoad" :items="roadsList" :displayField="'name'"
            :valueField="'id'" />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-15">
          <AmountsDropdown :label="$t('label.show_as')" v-model="showAmounts" @input="onAmountChange" />
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="checkAndGenerate"> {{ $t('buttons.generate') }} </md-button>
        </div>
        <div v-if="total > 0" class="md-layout-item btn-row md-small-size-50">
          <md-button class="md-raised md-success" @click="exportToExcel"> {{ $t('buttons.excel_export') }} </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <md-progress-spinner v-show="showSpinner" class="md-progress-spinner" :md-diameter="70" md-mode="indeterminate" />
      <md-table class='"paginated-table table-striped table-hover' :value="tableData" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell :md-label="$t('road_network.road')" md-sort-by="road_key">
            {{ item.road_key }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.section_description')" md-sort-by="section_description">
            {{ item.section_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.start_km')" md-sort-by="start_m" md-numeric>
            {{ item.start_m / 1000 | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.end_km')" md-sort-by="end_m" md-numeric>
            {{ item.end_m / 1000 | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.quantity')" md-sort-by="units" md-numeric>
            {{ item.units | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.units')" md-sort-by="unit_description" md-numeric>
            {{ item.unit_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('treatment.treatment_desc')" md-sort-by="treatment_type_description">
            {{ item.treatment_type_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('condition.priority_index')" md-sort-by="priority_index" md-numeric>
            {{ item.priority_index }}
          </md-table-cell>
          <md-table-cell :md-label="costLabel" md-sort-by="cost" md-numeric>
            {{ item.cost / showAmounts | numFormat }}
          </md-table-cell>
        </md-table-row>
      </md-table>
      <div class="footer-table md-table">
        <table>
          <tfoot>
            <tr>
              <th v-for="item in footerTable" :key="item" class="md-table-head">
                <div class="md-table-head-container md-ripple md-disabled">
                  <div class="md-table-head-label">{{ item }}</div>
                </div>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </md-card-content>
  </md-card>
</template>
<script>
  import { mapState, mapGetters } from 'vuex'
  import RegionsDropdown from '@/pages/Dropdowns/RegionsDropdown.vue'
  import BaseDropdown from '@/pages/Dropdowns/BaseDropdown.vue'
  import AmountsDropdown from '@/pages/Components/AmountsDropdown.vue'
  import { customSortMixin } from '@/mixins/customSortMixin'
  import messagesMixin from '@/mixins/messagesMixin'
  import { numFormat } from "@/store/refdata/format_helpers"
  import ExcelJS from 'exceljs'
  import { saveAs } from 'file-saver' // for saveing excel file

  export default {
    name: 'treatments-costs-form',
    mixins: [customSortMixin, messagesMixin],

    data() {
      return {
        showSpinner: true,
        selectedWorklist: null,
        selectedRegion: null,
        selectedRoad: null,

        showAmounts: 1000,
        costLabel: "Cost",
        currentSort: 'priority_index',
        currentSortOrder: 'desc',
      }
    },

    components: {
      RegionsDropdown,
      BaseDropdown,
      AmountsDropdown
    },

    mounted() {
      this.onAmountChange(this.showAmounts, this.$t("label.short_thousands"))
      this.loadWorksLists()
    },

    methods: {
      toggleSpinner(state) {
        this.showSpinner = state
      },

      onAmountChange(value, desc) {
        this.costLabel = `${this.$t('condition.cost')} ${desc}`
      },

      loadWorksLists() {
        this.toggleSpinner(true)
        this.$store.dispatch('LOAD_WORKS_LISTS', {}).then(() => {
          //this.selectedWorklist = this.savedWorksList.at(this.savedWorksList.length - 1).work_list_id
        })
        this.toggleSpinner(false)
      },

      loadTreatmentsList() {
        this.toggleSpinner(true)
        const params = {
          work_list_id: this.selectedWorklist,
          region_id: this.selectedRegion,
        }
        this.$store.dispatch('LOAD_ALL_WORKS', params).then(() => {
          this.toggleSpinner(false)
        })
      },

      async checkAndGenerate() {
        // Check whether there are approved homo_sections
        if (this.approvedYearsInHSList.length == 0) {
          this.errorMessage(this.$t('messages.no_approved_homosections'))
          return
        }
        this.showSpinner = true

        // Create list of years as an object for user selection
        const inputOptions = this.approvedYearsInHSList.reduce((acc, item) => {
          acc[item] = item
          return acc
        }, {})

        const { value: theYear } = await this.dropdownInput(
          this.$t('messages.treatment_generation_title'),
          inputOptions,
          this.$t('budget.select_year')
        )
        if (!theYear) {
          this.showSpinner = false
          return
        }

        // Check if already generated for this year
        const worksExist = this.savedWorksList.find(el => el.year == theYear)
        if (worksExist) {
          // Treatment list for the selected year exist, ask user whether to delete it
          const { value: confirmDeletion } = this.confirmation(
            this.$t('messages.treatment_exists_title'),
            this.$t('messages.treatment_exists')
          )

          // Exit if user selects cancel
          if (!confirmDeletion) {
            this.showSpinner = false
            return
          }

          try {
            await this.$store.dispatch('DEL_WORK_LIST', worksExist.work_list_id)
          } catch (err) {
            this.showSpinner = false
            this.errorMessage(`'Error while deleting work list: ${err}`)
            return
          }
        }
        this.generateWorks(theYear)
      },

      generateWorks(year) {
        this.$store.dispatch('GENERATE_WORKS', year).then((res) => {
          this.loadWorksLists()
          this.selectedWorklist = res.work_list_id
          this.successMessage(this.$t('messages.generated'), '')
          this.showSpinner = false
        })
      },

      formatRow(row, header = false, center = false) {
        // Apply formatting to the table header/footer
        const headerCellFill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF0070C0' } } // Blue
        const totalCellFill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF00' } } // yellow

        row.eachCell({ includeEmpty: true }, (cell) => { cell.fill = header ? headerCellFill : totalCellFill })
        row.font = { bold: true, color: { argb: header ? 'FFFFFFFF' : '00000000' } } // White text
        row.alignment = center ? { vertical: 'middle', horizontal: 'center' } : {}
      },

      async exportToExcel() {
        // Create new Excel file
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(this.selectedYear.toString());

        // Set and format the table headers
        worksheet.columns = this.getWorksheetColumns
        this.formatRow(worksheet.getRow(1), true, true)

        // Number formatting for the columns
        this.getWorksheetColumns.forEach(item => {
          if (item.num) worksheet.getColumn(item.key).numFmt = item.digits == 2 ? '#,##0.00' : '#,##0.000'
        })

        // Заполняем данные из this.tableData
        this.tableData.forEach(item => {
          const newRow = { ...item };
          newRow.cost /= this.showAmounts
          newRow.start_m /= 1000
          newRow.end_m /= 1000
          worksheet.addRow(newRow);
        });

        // Save
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `${this.$t('tabs.treatment_costs')}.xlsx`);
      }
    },

    computed: {
      ...mapState({
        savedWorksList: (state) => state.TitleList.worksLists,
        treatmentsList: (state) => state.TitleList.treatmentsList,
      }),
      ...mapGetters(['treatmentsSummary', 'approvedYearsInHSList', 'roadsInTreatmentsList']),
      roadsList() {
        return this.roadsInTreatmentsList(this.selecteRegion)
      },
      selectedYear() {
        return !this.selectedWorklist ? null : Number(this.savedWorksList.find(el => el.work_list_id == this.selectedWorklist).year)
      },
      footerTable() {
        return [
          this.$t('label.total'),
          `${this.$t('budget.works_count')}: ${numFormat(this.treatmentsSummary.totalRecords, 0)}`,
          `${this.$t('budget.total_cost')}: ${numFormat(this.treatmentsSummary.totalSum / this.showAmounts, 2)}`,
        ]
      },
      tableData() {
        return this.selectedRoad ? this.treatmentsList.filter((item) => {
          return item.fk_road == this.selectedRoad
        }) : this.treatmentsList
      },
      total() {
        return this.tableData.length
      },

      getWorksheetColumns() {
        return [
          { header: this.$t('road_network.region'), key: 'region_description', width: 30 },
          { header: this.$t('road_network.dep'), key: 'deu_description', width: 10 },
          { header: this.$t('road_network.road'), key: 'road_key', width: 15 },
          { header: this.$t('road_network.section_description'), key: 'section_description', width: 50 },
          { header: this.$t('road_network.start_km'), key: 'start_m', width: 10, num: true, digits: 3 },
          { header: this.$t('road_network.end_km'), key: 'end_m', width: 10, num: true, digits: 3 },
          { header: this.$t('label.quantity'), key: 'units', width: 10, num: true, digits: 2 },
          { header: this.$t('label.units'), key: 'unit_description', width: 10 },
          { header: this.$t('treatment.treatment_desc'), key: 'treatment_type_description', width: 40 },
          { header: this.$t('condition.priority_index'), key: 'priority_index', width: 10 },
          { header: this.costLabel, key: 'cost', width: 15, num: true, digits: 2 },
        ];
      },

    },
    watch: {
    }
  }
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>